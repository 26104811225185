import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { SignUpStageOneComponent } from './pages/sign-up-stage-one/sign-up-stage-one.component';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login"
  },
  {
    path: "login",
    component: LoginPageComponent,
    data: {
      title: LoginPageComponent.PAGE_TITLE,
    },
  },
  {
    path: "signup",
    component: SignUpStageOneComponent,
    data: {
      title: SignUpStageOneComponent.PAGE_TITLE,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
