<div class="container">
  <img
    src="/assets/images/logo_horizontal_transparent.png"
    alt="007 1337's Logo"
    class="logo"
  />
  <div class="tac">
    <p class="dib taj">
      The gates of hell are open night and day;<br />
      Smooth the descent, and easy is the way.
    </p>
  </div>
  <form class="form" method="POST" (submit)="login()">
    <div *ngIf="error.length > 0" class="error-box mb-20 animate__animated animate__headShake">
      <div class="box-icon">
        <span class="material-icons-sharp"> warning </span>
      </div>
      <p>{{ error }}</p>
    </div>
    <div class="input-group">
      <label for="email" aria-label="Email" class="input-icon">
        <span class="material-icons-sharp" aria-hidden="true"> chevron_right </span>
      </label>
      <input
        type="email"
        autocapitalize="off"
        name="email"
        id="email"
        [(ngModel)]="email"
        placeholder="name@domain.tld"
        autocomplete="off"
        required="required"
      />
    </div>
    <div class="input-group mt-10">
      <label for="password" aria-label="Password" class="input-icon">
        <span class="material-icons-sharp" aria-hidden="true"> chevron_right </span>
      </label>
      <input
        type="password"
        name="password"
        id="password"
        [(ngModel)]="password"
        placeholder="••••••••"
        required="required"
      />
    </div>
    <div class="tac mt-30">
      <button class="narrow-button" type="submit">enter</button>
    </div>
  </form>
  <div class="tac">
    <a class="narrow-button narrow-button--accent" [routerLink]="['/signup']">sign up</a>
  </div>
</div>
