import { Component, OnInit } from "@angular/core";
import { RecaptchaComponent } from "ng-recaptcha";
import { LoadingService } from "src/app/servies/loading.service";

@Component({
  selector: "app-sign-up-stage-one",
  templateUrl: "./sign-up-stage-one.component.html",
  styleUrls: ["./sign-up-stage-one.component.scss"],
})
export class SignUpStageOneComponent implements OnInit {
  static PAGE_TITLE: string = "Authentication Gate: Invite Code";

  error: string = "";

  inviteCode: string = "";
  // captcha: string = "";

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {}

  initiateSignUp(captchaRef: RecaptchaComponent) {
    this.loadingService.setLoading(true);
    captchaRef.execute();
  }

  async signUp(captcha: string): Promise<void> {
    this.error = "";
    this.loadingService.setLoading(true);
    const { inviteCode } = this;
    console.log({ inviteCode, captcha });
    await new Promise((resolve) => setTimeout(resolve, 1500));
    this.error = "x/wrong-invite";
    this.loadingService.setLoading(false);
  }
}
