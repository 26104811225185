import { Component, OnInit } from "@angular/core";
import { LoadingService } from 'src/app/servies/loading.service';

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  static PAGE_TITLE: string = "Authentication Gate";

  email: string = "";
  password: string = "";

  error: string = "";

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {}

  async login(): Promise<void> {
    const { email, password } = this;
    console.log({ email, password });
    this.error = "";
    this.loadingService.setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    this.error = "x/wrong-password";
    this.loadingService.setLoading(false);
  }
}
