<div class="container">
  <img
    src="/assets/images/logo_horizontal_transparent.png"
    alt="007 1337's Logo"
    class="logo"
  />

  <div>
    <p class="dib taj">Do you own an invite code?</p>
  </div>

  <form class="form" method="POST" (submit)="initiateSignUp(captchaRef)">
    <div
      *ngIf="error.length > 0"
      class="error-box mb-20 animate__animated animate__headShake"
    >
      <div class="box-icon">
        <span class="material-icons-sharp"> warning </span>
      </div>
      <p>{{ error }}</p>
    </div>

    <div class="input-group">
      <label for="invite-code" aria-label="invite-code" class="input-icon">
        <span class="material-icons-sharp" aria-hidden="true">
          chevron_right
        </span>
      </label>
      <input
        type="text"
        autocapitalize="off"
        name="invite_code"
        id="invite-code"
        [(ngModel)]="inviteCode"
        placeholder="XXX-XXXXX-XX-X"
        autocomplete="off"
        required="required"
      />
    </div>

    <div class="tac mt-30">
      <button class="narrow-button" type="submit">enter</button>
    </div>
  </form>

  <div class="tac">
    <p class="dib taj">
      If you don’t have an invite code, then you probably shouldn’t even be
      here...
    </p>
    <p class="dib taj mt-10">
      This platform is highly exclusive and invite-only.
    </p>
    <p class="dib taj mt-10">
      If you’re so persistent and want a code, email us at
      <a href="mailto:invite@0071337.xyz">invite@0071337.xyz</a>, We give codes
      when we feel like it sometimes.
    </p>
  </div>
</div>

<re-captcha
  size="invisible"
  #captchaRef="reCaptcha"
  theme="dark"
  (resolved)="$event && signUp($event)"
></re-captcha>