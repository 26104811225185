import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  // RecaptchaFormsModule,
  RECAPTCHA_LANGUAGE,
} from "ng-recaptcha";

import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { SignUpStageOneComponent } from "./pages/sign-up-stage-one/sign-up-stage-one.component";

@NgModule({
  declarations: [AppComponent, LoginPageComponent, SignUpStageOneComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    RecaptchaModule,
    // RecaptchaFormsModule,
  ],
  providers: [
    Title,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6LfnZ9UZAAAAALQMRaeMxCrwIBLq8wqt5nAuNuVa",
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "en",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
